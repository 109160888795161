import React, {useState, useEffect} from 'react';
import {Button, CircularProgress, Divider, Hidden} from '@material-ui/core';
import {fetchUser, saveUsersSkills} from "../../util/backendApi";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import OnboardingStepper from "./OnboardingStepper";
import {useNavigate, useParams} from "react-router-dom";

import {makeStyles} from "@material-ui/core/styles";
import Logo from "./Logo";
import Emoji, {JoyEnum} from "./Emoji";
import Container from "@material-ui/core/Container";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    logo: {
        textAlign: "center",
        paddingTop: "25px"
    },
    skillItem: {
        padding: '20px',
        marginTop: '20px',
        textTransform: "capitalize",
        textAlign: "center"
    },
    loadingContainer: {
        paddingTop: "50px"
    },
    paperContainer: {
        padding: "40px"
    },
    subHeadingContainer: {
        textAlign:"center"
    },
    subHeading: {
        paddingBottom: "10px"
    },
    skillRatingAvatar: {
        margin: "auto",
        fontSize: "30px",
        filter: "grayscale(100%)"
    },
    skillRatingAvatarSelected: {
        margin: "auto",
        fontSize: "40px",
    },
    skillRatingAvatarContainer: {
        cursor: 'pointer'
    },
    skillRatingTitle: {
        fontSize: "small"
    },
    skillRatingTitleSelect: {
        fontSize: "small",
        fontWeight: "bold"
    },
    saveButtonContainer: {
        padding: "50px"
    },
    skillRatingTitleHero: {
        fontWeight: "bold"
    },
    borderLeftAndRight: {
        borderLeft: "1px",
        borderRight: "1px"
    }
}))


function EnjoySkillsStep() {

    const classes = useStyles();
    const {t, i18n} = useTranslation()

    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [user, setUser] = useState(null)

    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        setIsLoading(true)
        fetchUser(id).then(user => {
            setUser(user)
            setIsLoading(false)
        })
    },[id]);

    const rateSkill = async (skillUri, rating) => {
        if (user) {
            if (user.skills) {
                for (let key in user.skills) {
                    if (user.skills[key].uri === skillUri) {
                        user.skills[key].joyRating = rating;
                        setUser(Object.assign({}, user));
                    }
                }
            }
        }
    }

    const buildSkillsList = () => {
        const skills = [];
        if (user) {
            if (user.skills) {
                for (let key in user.skills) {
                    skills.push(skillRatingJsx(user.skills[key]))
                }
            }
        }

        return skills;
    }

    const skillRatingJsx  = (skill) => {
        if (!skill.joyRating) {
            skill.joyRating = JoyEnum.LIKE
        }
        return (
            <Grid item lg={6} key={skill.uri}>
                <Paper className={classes.skillItem} elevation={3} >
                    <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                        <Grid item xs={12} className={classes.subHeadingContainer}>
                            <Typography variant="h6">{skill.preferredLabel[i18n.language]}</Typography>
                            <Divider/>
                        </Grid>
                        <Grid onClick={() => rateSkill(skill.uri, JoyEnum.HATE)}  item xs={3} className={classes.skillRatingAvatarContainer}>
                            <div className={skill.joyRating === JoyEnum.HATE ? classes.skillRatingAvatarSelected : classes.skillRatingAvatar}>
                                <Emoji joy={JoyEnum.HATE}/>
                            </div>
                            <Hidden mdDown><Typography className={skill.joyRating === JoyEnum.HATE ? classes.skillRatingTitleSelect : classes.skillRatingTitle} >{t("enjoySkillsStep.hateIt")}</Typography></Hidden>
                        </Grid>
                        <Grid onClick={() => rateSkill(skill.uri, JoyEnum.DISLIKE)}  item xs={3} className={classes.skillRatingAvatarContainer}>
                            <div className={skill.joyRating === JoyEnum.DISLIKE ? classes.skillRatingAvatarSelected : classes.skillRatingAvatar}>
                                <Emoji joy={JoyEnum.DISLIKE}/>
                            </div>
                            <Hidden mdDown><Typography className={skill.joyRating === JoyEnum.DISLIKE ? classes.skillRatingTitleSelect : classes.skillRatingTitle} >{t("enjoySkillsStep.dislikeIt")}</Typography></Hidden>
                        </Grid>
                        <Grid onClick={() => rateSkill(skill.uri, JoyEnum.LIKE)} item xs={3} className={classes.skillRatingAvatarContainer}>
                            <div className={skill.joyRating === JoyEnum.LIKE ? classes.skillRatingAvatarSelected : classes.skillRatingAvatar}>
                                <Emoji joy={JoyEnum.LIKE}/>
                            </div>
                            <Hidden  mdDown><Typography className={skill.joyRating === JoyEnum.LIKE ? classes.skillRatingTitleSelect : classes.skillRatingTitle} >{t("enjoySkillsStep.likeIt")}</Typography></Hidden>
                        </Grid>
                        <Grid onClick={() => rateSkill(skill.uri, JoyEnum.LOVE)} item xs={3} className={classes.skillRatingAvatarContainer}>
                            <div className={skill.joyRating === JoyEnum.LOVE ? classes.skillRatingAvatarSelected : classes.skillRatingAvatar}>
                                <Emoji joy={JoyEnum.LOVE}/>
                            </div>
                            <Hidden mdDown><Typography className={skill.joyRating === JoyEnum.LOVE ? classes.skillRatingTitleSelect : classes.skillRatingTitle} >{t("enjoySkillsStep.loveIt")}</Typography></Hidden>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        )
    }

    const saveUserSkillsBackend = async () => {
        setIsSaving(true)
        try {
            await saveUsersSkills(user.id, user.skills)
            navigate('/dashboard/' + id)
        } catch (e) {
            console.error(e)
            setIsSaving(false)
        }
    }

    return (
        <React.Fragment>
            <Container>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Logo/>
                </Grid>
                <OnboardingStepper activeStep={2}/>

                {isLoading ?
                    <Grid container className={classes.loadingContainer} direction="row" justify="center" alignItems="center">
                        <CircularProgress/>&nbsp;
                        <div>
                            <Typography variant="subtitle1">{t("enjoySkillsStep.loading")}</Typography>
                        </div>
                    </Grid>
                    :
                    <React.Fragment>
                        <Grid container direction="row" justify="center" alignItems="center">
                            <Grid item lg={12} className={classes.subHeadingContainer}>
                                <Typography className={classes.subHeading} variant="h4">{t("enjoySkillsStep.title")}</Typography>

                                <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                                    <Grid item lg={1}>
                                        <div className={classes.skillRatingAvatarSelected}><Emoji joy={JoyEnum.HATE}/></div>
                                        <Typography className={classes.skillRatingTitleHero} variant="subtitle1">{t("enjoySkillsStep.hateIt")}.</Typography>
                                    </Grid>
                                    <Grid item lg={1} className={classes.borderLeftAndRight}>
                                        <div className={classes.skillRatingAvatarSelected}><Emoji joy={JoyEnum.DISLIKE}/></div>
                                        <Typography className={classes.skillRatingTitleHero} variant="subtitle1">{t("enjoySkillsStep.dislikeIt")}.</Typography>
                                    </Grid>
                                    <Grid item lg={1} className={classes.borderLeftAndRight}>
                                        <div className={classes.skillRatingAvatarSelected}><Emoji joy={JoyEnum.LIKE}/></div>
                                        <Typography className={classes.skillRatingTitleHero} variant="subtitle1">{t("enjoySkillsStep.likeIt")}.</Typography>
                                    </Grid>
                                    <Grid item lg={1}>
                                        <div className={classes.skillRatingAvatarSelected}><Emoji joy={JoyEnum.LOVE}/></div>
                                        <Typography className={classes.skillRatingTitleHero} variant="subtitle1">{t("enjoySkillsStep.loveIt")}.</Typography>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid container direction="row" justify="center" alignItems="center">
                            <Grid item lg={10}>
                                <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
                                    {buildSkillsList()}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container className={classes.saveButtonContainer} direction="row" justify="center" alignItems="center">
                            {
                                isSaving ?
                                    <CircularProgress/> :
                                    <Button size="large" variant="contained" color="primary" onClick={saveUserSkillsBackend}>{t("next")} →</Button>
                            }
                        </Grid>
                    </React.Fragment>
                }
            </Container>
        </React.Fragment>

    );
}

export default EnjoySkillsStep;
