import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Reward from "react-rewards";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import computerGuy from "../../static/computerGuy.svg";
import {Collapse} from "@material-ui/core";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Emoji from "../onboarding/Emoji";
import {Route, Routes, useNavigate} from "react-router-dom";
import {Skeleton} from "@material-ui/lab";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    capitalize: {
        textTransform: 'capitalize',
    },
    title: {
        textTransform: 'capitalize',
        textAlign: 'center'
    },
    descriptionShort: {
        // display: '-webkit-box',
        // overflow: 'hidden',
        // WebkitBoxOrient: 'vertical',
        // WebkitLineClamp: 3
    },
    descriptionLong: {
    },
    subtitle: {
        textAlign: 'center'
    },
    container: {
        margin: 30
    },
    lovedSkillContainer: {
        border: 'solid',
        borderRadius: 30,
        padding: 20,
        borderColor: '#DA0063',
        margin: 20,
        position: 'relative'
    },
    normalSkillContainer: {
        border: 'solid',
        borderRadius: 30,
        padding: 20,
        borderColor: '#0016da',
        margin: 20,
        position: 'relative'
    },
    missingEssentialSkillContainer: {
        border: 'solid',
        borderRadius: 30,
        padding: 20,
        borderColor: '#00c1da',
        margin: 20,
        position: 'relative'
    },
    missingOptionalSkillContainer: {
        border: 'solid',
        borderRadius: 30,
        padding: 20,
        borderColor: '#00c1da',
        margin: 20,
        position: 'relative'
    },
    skillTitle: {
        textTransform: 'capitalize',
        textAlign: 'center'
    },
    skillRatingAvatar: {
        margin: "auto",
        fontSize: "40px",
        position: 'absolute',
        right: 40,
        top: -27
    },
    emptyList: {
        textAlign: 'center',
        border: 'solid',
        borderRadius: 30,
        padding: 20,
        borderColor: '#909a9b',
        margin: 20,
        position: 'relative'
    },
    emptyListIcon: {
        fontSize: '3em',
    },
    missingSkillHeading: {
        textTransform: 'capitalize',
        marginLeft: '10px'
    },
    missingSkillSubHeading: {
        marginLeft: '10px',
        color: "rgba(0, 0, 0, 0.54)"
    },
    missingSkillPercentageJobAd: {
        fontWeight: "bold",
        color: "#dd8407"
    },
    missingSkillPercentageJobCv: {
        fontWeight: "bold",
        color: "#076edd"
    },
    paperContainer: {
        textAlign: 'center'
    },
    largeButtonContainer: {
    },
    largeButton: {
        margin: 10,
        flexGrow: 1,
        textAlign: 'center',
        position: 'relative',
        overflow: 'hidden',
        transition: 'background 400ms',
        color: '#000',
        // backgroundColor: '#DA0063',
        padding: '0.5rem 1rem',
        fontSize: '1.1rem',
        outline: 0,
        borderRadius: '0.25rem',
        boxShadow: '0 0 0.5rem rgba(0, 0, 0, 0.3)',
        cursor: 'pointer',
        border: 'solid',
        borderColor: '#c3c3c3',
        borderWidth: '2px'

    },
    menuButton: {
        marginRight: theme.spacing(2),
    }
}));

let reward;

const LOVED_SKILLS = 'loved-skills'
const NOT_LOVED_SKILLS = 'not-Loved-skills'
const MISSING_ESSENTIAL_SKILLS = 'missing-essential-skills'

export default function MobileOverview({occupation, essentialSkills, lovedSkills, notLovedSkills, missingEssentialSkills, missingOptionalSkills}) {

    useEffect(() => {
        if (reward && reward.rewardMe) {
            setTimeout(() => {
                reward.rewardMe()
            }, 500)
        }
    }, [])

    return (
        <Container>
            <div style={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>

                <Routes>
                    <Route path="" element={<Overview occupation={occupation} lovedSkills={lovedSkills} notLovedSkills={notLovedSkills} missingEssentialSkills={missingEssentialSkills} missingOptionalSkills={missingOptionalSkills}/>} />
                    <Route path={LOVED_SKILLS} element={<LovedSkills lovedSkills={lovedSkills}/>} />
                    <Route path={NOT_LOVED_SKILLS} element={<NotLovedSkills skills={notLovedSkills}/>} />
                    <Route path={MISSING_ESSENTIAL_SKILLS} element={<MissingEssentialSkills essentialSkills={essentialSkills} missingEssentialSkills={missingEssentialSkills}/>} />
                </Routes>

            </div>
        </Container>
    )
}

function Overview({occupation, lovedSkills, notLovedSkills, missingEssentialSkills, missingOptionalSkills}) {

    const classes = useStyles();
    const navigate = useNavigate();
    const {t, i18n} = useTranslation()

    const [isLongDescription, setIsLongDescription] = useState(false)

    return (
        <React.Fragment>
            <Grid container justify="center" alignItems="center" spacing={3}>
                <Grid item sm={12} style={{textAlign: 'center', marginTop: '10px'}}><img src={computerGuy} width={"80%"} alt={"Welcome"} /></Grid>
                <Grid item sm={12}>
                    <div  className={classes.paperContainer}>
                        <Reward
                            ref={(ref) => { reward = ref }}
                            type='confetti'
                            config={{
                                lifetime: 150,
                                spread: 180,
                                startVelocity: 35,
                                elementCount: 150,
                                springAnimation: false
                            }}>

                            <Typography className={classes.title} variant="h5">{occupation.preferredLabel[i18n.language]}</Typography>
                        </Reward>

                        <Collapse in={isLongDescription} collapsedHeight={70}>
                            <Typography className={isLongDescription ? classes.descriptionLong : classes.descriptionShort}>{occupation.description[i18n.language].literal}</Typography>
                        </Collapse>
                        <Typography onClick={() => setIsLongDescription(!isLongDescription)}>{isLongDescription ? <ExpandLessIcon/> : <ExpandMoreIcon/>}</Typography>
                    </div>
                </Grid>
            </Grid>


            <div style={{display: 'flex', flexGrow: 1, flexDirection: 'column', flexWrap: 'wrap'}}>
                <div className={classes.largeButtonContainer}>
                    <div className={classes.largeButton} onClick={() => navigate(LOVED_SKILLS)}>
                        <span role="img" aria-label="Love it" style={{fontSize: '3em'}}>😍</span>
                        <Typography className={classes.title} variant="h5">{t("overview.lovedSkillsTitle")}</Typography>
                        <Typography className={classes.title} variant="h5">({lovedSkills.length})</Typography>
                    </div>
                </div>
                <div className={classes.largeButtonContainer}>
                    <div className={classes.largeButton} onClick={() => navigate(NOT_LOVED_SKILLS)}>
                        <span role="img" aria-label="Love it" style={{fontSize: '3em'}}>🙂</span>
                        <Typography className={classes.title} variant="h5">{t("overview.normalSkillsTitle")}</Typography>
                        <Typography className={classes.title} variant="h5">({notLovedSkills.length})</Typography>
                    </div>
                </div>
                <div className={classes.largeButtonContainer}>
                    <div className={classes.largeButton} onClick={() => navigate(MISSING_ESSENTIAL_SKILLS)}>
                        <span role="img" aria-label="Love it" style={{fontSize: '3em'}}>🤓</span>
                        <Typography className={classes.title} variant="h5">{t("overview.essentialSkillsTitle")}</Typography>
                        <Typography className={classes.title} variant="h5">({missingEssentialSkills.length})</Typography>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

function LovedSkills({lovedSkills}) {

    window.scrollTo(0, 0);
    const {t, i18n} = useTranslation()


    const classes = useStyles();
    let lovedSkillsList = []
    if (lovedSkills.length > 0) {
        lovedSkillsList = lovedSkills.map(skill => {
            return (
                <div key={skill.uri} className={classes.lovedSkillContainer}>
                    <Typography className={classes.title} variant="h6">{skill.preferredLabel[i18n.language]}</Typography>
                    <Typography>{skill.description[i18n.language]}</Typography>
                    <div className={classes.skillRatingAvatar}>
                        <Emoji joy={skill.joyRating}/>
                    </div>
                </div>
            )
        })
    } else {
        lovedSkillsList = (
            <div className={classes.emptyList}>
                <NewReleasesIcon className={classes.emptyListIcon}/>
                <Typography variant="subtitle1">{t("overview.lovedSkillsEmpty")}</Typography>
            </div>
        )
    }
    return (
        <React.Fragment>
            <BackToOverview color={'#DA0063'}/>
            <div style={{marginTop: '60px', zIndex: 5}}>
                <Typography className={classes.title} variant="h5">{t("overview.lovedSkillsTitle")}</Typography>
                <Typography className={classes.subtitle} variant="subtitle1">{t("overview.lovedSkillsSubtitle")}</Typography>
                {lovedSkillsList}
            </div>
        </React.Fragment>
    )
}

function NotLovedSkills({skills}){

    window.scrollTo(0, 0);
    const {t, i18n} = useTranslation()


    const classes = useStyles();
    let skillsList = []

    if (skills.length > 0) {
        skillsList = skills.map(skill => {
            return (
                <div key={skill.uri} className={classes.normalSkillContainer}>
                    <Typography className={classes.title} variant="h6">{skill.preferredLabel[i18n.language]}</Typography>
                    <Typography>{skill.description[i18n.language]}</Typography>
                    <div className={classes.skillRatingAvatar}>
                        <Emoji joy={skill.joyRating}/>
                    </div>
                </div>
            )
        })
    } else {
        skillsList = (
            <div className={classes.emptyList}>
                <NewReleasesIcon className={classes.emptyListIcon}/>
                <Typography variant="subtitle1">{t("overview.essentialSkillsEmpty")}</Typography>
            </div>
        )
    }

    return (
        <React.Fragment>
            <BackToOverview color={'#0016da'}/>
            <div style={{marginTop: '60px', zIndex: 5}}>
                <Typography className={classes.title} variant="h5">{t("overview.normalSkillsTitle")}</Typography>
                <Typography className={classes.subtitle} variant="subtitle1">{t("overview.normalSkillsSubtitle")}</Typography>
                {skillsList}
            </div>
        </React.Fragment>
    )
}

function MissingEssentialSkills({essentialSkills, missingEssentialSkills}) {

    window.scrollTo(0, 0);
    const {t, i18n} = useTranslation()

    const classes = useStyles();
    let skillsList = []

    if (missingEssentialSkills.length > 0) {
        skillsList = missingEssentialSkills.map(skill => {
            return (
                <div key={skill.uri} className={classes.missingEssentialSkillContainer}>
                    <Typography className={classes.title} variant="h6">{skill.title}</Typography>
                    { essentialSkills && essentialSkills[skill.uri] ? <Typography>{essentialSkills[skill.uri].description[i18n.language].literal}</Typography> : renderLoadingDescription()}
                </div>
            )
        })
    } else {
        skillsList = (
            <div className={classes.emptyList}>
                <NewReleasesIcon className={classes.emptyListIcon}/>
                <Typography variant="subtitle1">{t("overview.essentialSkillsEmpty")}</Typography>
            </div>
        )
    }

    return (
        <React.Fragment>
            <BackToOverview color={'#00c1da'}/>
            <div style={{marginTop: '60px', zIndex: 5}}>
                <Typography className={classes.title} variant="h5">{t("overview.essentialSkillsTitle")}</Typography>
                <Typography className={classes.subtitle} variant="subtitle1">{t("overview.essentialSkillsSubtitle")}</Typography>
                {skillsList}
            </div>
        </React.Fragment>
    )
}

const renderLoadingDescription = () => {
    return (
        <React.Fragment>
            <Skeleton animation="wave" height={20} width="100%" style={{ marginBottom: 6 }} />
            <Skeleton animation="wave" height={20} width="100%" style={{ marginBottom: 6 }} />
            <Skeleton animation="wave" height={20} width="100%" style={{ marginBottom: 6 }} />
        </React.Fragment>
    )
}

function BackToOverview({color}) {
    const navigate = useNavigate();
    const {t} = useTranslation()

    return (
        <div  onClick={() => navigate(-1)} style={{position: 'fixed', width:'100%', color: "#fff", padding: '10px', backgroundColor: color, left: 0, top: 0, zIndex: 10}}>
            <Typography><ArrowBackIcon style={{verticalAlign: 'bottom'}}/> {t("overview.backToOverview")}</Typography>
        </div>
    )
}
