import axios from "axios";
import qs from "qs";

const BASE_URL = "https://ec.europa.eu/esco/api"

export const fetchOccupationLabels = async (text, language) => {
    const params = {
        text:'*' + text.replace(" ", "*") + '*',
        language: language,
        type:["occupation"],
        limit:50,
        offset:0,
        alt:true
    }

    const params2 = {
        text: text,
        language:language,
        type:["occupation"],
        limit:50,
        offset:0,
        alt:true
    }

    const search1 = axios.get(BASE_URL + '/suggest?' + qs.stringify(params, {arrayFormat: 'repeat'}))
    const search2 = axios.get(BASE_URL + '/suggest2?' + qs.stringify(params2, {arrayFormat: 'repeat'}))

    return Promise.all([search1, search2])
        .then(results => {
            let options = [];
            if (results[0].data._embedded && results[0].data._embedded.results) {
                options = options.concat(results[0].data._embedded.results);
            }
            if (results[1].data._embedded && results[1].data._embedded.results) {
                options = options.concat(results[1].data._embedded.results);
            }
            return options
        }).catch(error => {
            console.error("Error in Axios call", error)
        })
}

export const fetchOccupation = async (occupationUri, language) => {
    const params = {
        uri:occupationUri,
        language: language
    }

    return axios.get(BASE_URL + '/resource/occupation?' + qs.stringify(params, {arrayFormat: 'repeat'}))
        .then(data => {
            return data.data;
        })
        .catch(error => {
            console.error("Error in Axios call", error)
        })
}

export const fetchSkills = async (skillsUris, language) => {
    ///resource/skill?uris=http://data.europa.eu/esco/skill/a507f45d-0894-4803-b1cd-9ca7f15c9299&uris=http://data.europa.eu/esco/skill/2afb2b59-c9a3-4cf3-b1dd-1a2fad51e583&language=en
    const params = {
        uris: [skillsUris],
        language: language
    }

    return axios.get(BASE_URL + '/resource/skill?' + qs.stringify(params, {arrayFormat: 'repeat'}))
        .then(data => {
            return data.data;
        })
        .catch(error => {
            console.error("Error in Axios call", error)
        })
}
