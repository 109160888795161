import React from 'react';
import logo from '../../static/21skills_logo_colored.png';
import Grid from "@material-ui/core/Grid";

function Logo() {
    return (
        <Grid item lg={10} style={{textAlign: "center", paddingTop: "20px", paddingBottom: "20px"}}>
            <img src={logo} alt="Logo" height="100px"/>
        </Grid>
    )
}

export default Logo;
