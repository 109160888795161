import React, {useCallback, useEffect, useState} from 'react';
import OccupationStep from "./OccupationStep";
import {
    Routes,
    Route,
    useNavigate
} from 'react-router-dom';
import EssentialSkillsStep from "./EssentialSkillsStep";
import {saveUsersOccupation, fetchUser} from "../../util/backendApi";
import {fetchOccupation, fetchSkills} from "../../util/escoApi";
import EnjoySkillsStep from "./EnjoySkillsStep";
import Welcome from "./Welcome";
import DashboardContainer from "../dashboard/DashboardContainer";
import {useTranslation} from "react-i18next";

function OnboardingFlow() {

    const [user, setUser] = useState()

    const [occupation, setOccupation] = useState();
    const [occupationAutocomplete, setOccupationAutocomplete] = useState();

    const [isFetchingSkills, setIsFetchingSkills] = useState(false)
    const [skills, setSkills] = useState({})
    const [essentialSkills, setEssentialSkills] = useState({})

    const navigate = useNavigate();
    const {i18n} = useTranslation()

    const querySkills = useCallback(async (occupation) => {
        setIsFetchingSkills(true);

        let essentialSkillUris = []
        let optionalSkillUris = [];

        if (occupation && occupation._links) {
            if (occupation._links.hasEssentialSkill) {
                essentialSkillUris = occupation._links.hasEssentialSkill.map(essentialSkill => {
                    return essentialSkill.uri;
                })
            }
            if (occupation._links.hasOptionalSkill) {
                optionalSkillUris = occupation._links.hasOptionalSkill.map(optional => {
                    return optional.uri;
                })
            }
        }
        let promises = [fetchSkills(essentialSkillUris, i18n.language)]
        if (optionalSkillUris.length) {
            promises.push(fetchSkills(optionalSkillUris, i18n.language))
        }

        const skillsReturned = await Promise.all(promises)

        let skills = {}
        if (skillsReturned[0] && skillsReturned[0]._embedded) {
            skills = skillsReturned[0]._embedded
            setEssentialSkills(skillsReturned[0]._embedded)
        }

        if (skillsReturned[1] && skillsReturned[1]._embedded) {
            skills = {...skills, ...skillsReturned[1]._embedded}
        }

        setSkills(skills)
        setIsFetchingSkills(false);
    }, [i18n.language])

    useEffect(() => {
        if (occupationAutocomplete) {
            fetchOccupation(occupationAutocomplete.uri, i18n.language).then((fetchedOccupation) => {
                querySkills(fetchedOccupation)
                setOccupation(fetchedOccupation);
            })
        }
    }, [occupationAutocomplete, i18n.language, querySkills])

    const saveOccupationBackend = async () => {
        if (occupation) {
            const data  = await saveUsersOccupation(occupation)
            //need to handle error when this fucks up
            const user = await fetchUser(data.id);
            setUser(user)
            setOccupation(user.occupation);
            sendSegmentIdentify(data.id)
            navigate('/essential-skills/'+ data.id)
        } else {
            console.log('No occupation')
        }
    }

    const sendSegmentIdentify = (id) => {
        window.analytics.identify(id, {});
    }

    const fetchProps = useCallback(async (id) => {
        const user = await fetchUser(id);
        setUser(user)
        setOccupation(user.occupation);
        querySkills(user.occupation)
    }, [querySkills])

    return (
        <React.Fragment>
            <Routes>
                <Route path="/" element={<Welcome />} />
                <Route path="/*" element={<Welcome />} />
                <Route path="/occupation" element={<OccupationStep occupation={occupation} setOccupation={setOccupationAutocomplete} saveOccupationBackend={saveOccupationBackend} />} />
                <Route path="/occupation/:id" element={<OccupationStep occupation={occupation} setOccupation={setOccupationAutocomplete} saveOccupationBackend={saveOccupationBackend} />} />
                <Route path="/essential-skills/:id" element={<EssentialSkillsStep user={user} occupation={occupation} fetchProps={fetchProps} skillsProp={skills} isFetchingSkills={isFetchingSkills} />} />
                <Route path="/enjoy-skills/:id" element={<EnjoySkillsStep/>}/>
                <Route path="/dashboard/:id*" element={<DashboardContainer user={user} occupation={occupation} fetchProps={fetchProps} essentialSkills={essentialSkills} isFetchingSkills={isFetchingSkills} />} />
            </Routes>
        </React.Fragment>
    );
}

export default OnboardingFlow;
