import axios from "axios";
import qs from "qs";

const BASE_URL = "https://uob1nh39bc.execute-api.us-east-1.amazonaws.com/dev"

export const saveUsersOccupation = async (occupation, yearsExperience) => {
    const body = {
        occupation: occupation,
        yearsExperience
    }

    return axios.post(BASE_URL + '/user/occupation', body)
        .then(data => {
            console.log(data)
            return data.data;
        })
        .catch(error => {
            console.error("Error in Axios call to 21skills backend", error)
        })
}

export const saveUsersEssentialSkills = async (id, essentialSkills) => {
    const body = {
        id: id,
        essentialSkills: essentialSkills
    }

    return axios.post(BASE_URL + '/user/essential-skills', body)
        .then(data => {
            console.log(data)
            return data.data;
        })
        .catch(error => {
            console.error("Error in Axios call to 21skills backend", error)
        })
}

export const saveUsersSkills = async (id, skills) => {
    const body = {
        id: id,
        skills: skills
    }

    return axios.post(BASE_URL + '/user/skills', body)
        .then(data => {
            console.log(data)
            return data.data;
        })
        .catch(error => {
            console.error("Error in Axios call to 21skills backend", error)
        })
}

export const addEssentialSkillsToUser = async (id, essentialSkills) => {
    const body = {
        id: id,
        essentialSkills: essentialSkills
    }

    return axios.post(BASE_URL + '/user/essential-skills/add', body)
        .then(data => {
            console.log(data)
            return data.data;
        })
        .catch(error => {
            console.error("Error in Axios call to 21skills backend", error)
        })
}

export const getOccupationStats = async (occupationUri) => {
    const params = {
        id: occupationUri
    }

    return axios.get(BASE_URL + '/occupation/stats?' + qs.stringify(params))
        .then(data => {
            console.log(data)
            return data.data;
        })
        .catch(error => {
            console.error("Error in Axios call to 21skills backend", error)
        })
}


export const fetchUser = async (id) => {
    const params = {
        id: id
    }

    return axios.get(BASE_URL + '/user?' + qs.stringify(params))
        .then(data => {
            return data.data;
        })
        .catch(error => {
            console.error("Error in Axios call to 21skills backend", error)
        })
}
