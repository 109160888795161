import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import {fetchOccupationLabels} from "../../util/escoApi";
import {makeStyles} from "@material-ui/core/styles";
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        textTransform: 'capitalize',
    },
}));

export default function OccupationAutocomplete({onChange}) {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [timeoutId, setTimeoutId] = React.useState();

    const classes = useStyles();
    const {t, i18n} = useTranslation();

    const filterOptions = createFilterOptions({
        stringify: option => option.title + ' '  + option.searchHit,
    });

    const fetchOptions = (text) => {
        let active = true;

        (async () => {
            setLoading(true);
            const occupations = await fetchOccupationLabels(text, i18n.language);
            if (occupations && Array.isArray(occupations)) {
                for (let i = 0; i < occupations.length; i++) {
                    let match = false;
                    for (let j = 0; j < options.length; j++) {
                        if (occupations[i].uri === options[j].uri) {
                            match = true;
                            break;
                        }
                    }
                    if (!match) {
                        options.push(occupations[i])
                    }
                }

                if (active) {
                    setOptions(occupations);
                }
            }

            setLoading(false);

        })();

    }

    const onInputChange = newInputValue => {
        clearTimeout(timeoutId)
        if (newInputValue && newInputValue.length > 0) {
            const localTimeoutId = setTimeout(() => fetchOptions(newInputValue), 500)
            setTimeoutId(localTimeoutId)
        }
    }

    return (
        <Autocomplete
            id="occupation-selector"
            className={classes.root}
            filterOptions={filterOptions}
            style={{ width: 300, display: "inline-flex"}}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            onChange={(event, value) => {
                onChange(value)
            }}
            onInputChange={(event, newInputValue)  => {
                onInputChange(newInputValue)
            }}
            getOptionSelected={(option, value) => option.uri === value.uri}
            getOptionLabel={(option) => option.title === option.searchHit  ? option.title : option.title + ' (' + option.searchHit  + ')'}
            options={options}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={t("occupationAutocomplete.label")}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}
