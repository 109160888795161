import React, {useState, useEffect} from 'react';
import {Button, CircularProgress, Container} from '@material-ui/core';
import {saveUsersEssentialSkills} from "../../util/backendApi";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import OnboardingStepper from "./OnboardingStepper";
import {useNavigate, useParams} from "react-router-dom";
import computerGuy from "../../static/computerGuy.svg";
import {makeStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Logo from "./Logo";
import {useTranslation, Trans} from "react-i18next";
import { fade } from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    logo: {
        textAlign: "center",
        paddingTop: "25px"
    },
    skillItemSelected: {
        padding: '20px',
        margin: '20px',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        cursor: 'pointer',
        textTransform: "capitalize",
        textAlign: "center"
    },
    skillItem: {
        padding: '20px',
        margin: '20px',
        backgroundColor: fade(theme.palette.primary.light, 0.3),
        cursor: 'pointer',
        textTransform: "capitalize",
        textAlign: "center"
    },
    loadingContainer: {
        paddingTop: "50px"
    },
    paperContainer: {
        padding: "40px"
    },
    subHeadingContainer: {
        textAlign:"center",
        padding: "25px"
    },
    saveButtonContainer: {
        padding: "50px"
    }
}))


function EssentialSkillsStep({user, skillsProp, isFetchingSkills, fetchProps}) {

    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const [skills, setSkills] = useState({});
    const [isSaving, setIsSaving] = useState(false);
    const [isFetchingUser, setIsFetchingUser] = useState(true)
    const navigate = useNavigate();

    const { id } = useParams();

    useEffect(() => {
        if (!user) {
            setIsFetchingUser(true)
            fetchProps(id).then(() => {
                setIsFetchingUser(false)
            })
        } else {
            setIsFetchingUser(false)
        }
    },[id, user, fetchProps]);

    useEffect(() => {
        if (user && skillsProp) {
            let skills = Object.assign({}, skillsProp)
            if (user.skills && Array.isArray(user.skills)) {
                user.skills.forEach(skill => {
                    if (skills[skill.uri]) {
                        skills[skill.uri].isSelected = true
                    }
                })
            }
            setSkills(skills)
        }

    }, [skillsProp, user])

    const selectSkill = async (skill) => {
        const updatedSkills = Object.assign({}, skills);
        updatedSkills[skill.uri].isSelected = !updatedSkills[skill.uri].isSelected;
        setSkills(updatedSkills)
    }

    const buildSkillsList = () => {
        const skillsList = [];
        for (let key in skills) {
            const skill = skills[key];
            if (skill.isSelected) {
                skillsList.push(
                    <Grid key={skill.uri} item lg={6}>
                        <Tooltip title={skill.description[i18n.language].literal} aria-label="description">
                            <Paper onClick={() => selectSkill(skill)} className={classes.skillItemSelected} elevation={3} >{skill.preferredLabel[i18n.language]}</Paper>
                        </Tooltip>
                    </Grid>
                )
            } else {
                skillsList.push(
                    <Grid key={skill.uri} item lg={6}>
                        <Tooltip title={skill.description[i18n.language].literal} aria-label="description">
                            <Paper onClick={() => selectSkill(skill)} className={classes.skillItem} elevation={3} >{skill.preferredLabel[i18n.language]}</Paper>
                        </Tooltip>
                    </Grid>
                )
            }

        }
        return skillsList;
    }

    const skillsListJsx = buildSkillsList();

    const saveEssentialSkillsBackend = async () => {
        setIsSaving(true)
        try {
            const selectedSkills = [];
            for (let key in skills) {
                if (skills[key].isSelected) {
                    selectedSkills.push(skills[key])
                }
            }
            await saveUsersEssentialSkills(id, selectedSkills)
            navigate('/enjoy-skills/' + id)
        } catch (e) {
            console.error(e)
            setIsSaving(false)
        }
    }

    return (
        <React.Fragment>
            <Container>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Logo/>
                </Grid>
                <OnboardingStepper activeStep={1}/>

                {isFetchingSkills || isFetchingUser || user === undefined || user.occupation === undefined ?
                    <Grid container className={classes.loadingContainer} direction="row" justify="center" alignItems="center">
                        <CircularProgress/>&nbsp;
                        <div>
                            <Typography variant="subtitle1">{t('essentialSkillsStep.loading')}</Typography>
                        </div>
                    </Grid>
                    :
                    <React.Fragment>
                        <Grid container direction="row" justify="center" alignItems="center">
                            <Grid item lg={6}>
                                <Typography variant="h5">
                                    <Trans i18nKey="essentialSkillsStep.title" values={{ skillsCount: skillsListJsx.length, occupation: user.occupation.preferredLabel[i18n.language]}}>
                                        Here are <b>{skillsListJsx.length} skills</b> that a {user.occupation.preferredLabel[i18n.language]} usually have. Which ones do you have?
                                    </Trans>
                                </Typography>
                                <Typography variant="subtitle1">{t('essentialSkillsStep.subtitle', {occupation: user.occupation.preferredLabel[i18n.language]})}</Typography>
                            </Grid>
                            <Grid item lg={1}>
                            </Grid>
                            <Grid item lg={5}>
                                <img src={computerGuy} height={"250px"} alt={"Computer man"} />
                            </Grid>
                        </Grid>
                        <div>
                            <Grid container direction="row" justify="center" alignItems="center">
                                <Grid item lg={10} className={classes.subHeadingContainer} >
                                    <Typography variant="h6"><b>{t('essentialSkillsStep.instructions')}</b></Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="center" alignItems="center">
                                <Grid item lg={10}>
                                    <Grid container direction="row" justify="center" alignItems="center">
                                        {skillsListJsx}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>

                        <Grid container className={classes.saveButtonContainer} direction="row" justify="center" alignItems="center">
                            {
                                isSaving ?
                                    <CircularProgress/> :
                                    <Button size="large" variant="contained" color="primary" onClick={saveEssentialSkillsBackend}>{t('next')} →</Button>
                            }
                        </Grid>
                    </React.Fragment>

                }
            </Container>
        </React.Fragment>

    );
}

export default EssentialSkillsStep;
