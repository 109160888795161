import React, {useState} from 'react';
import {Button, CircularProgress, Container} from '@material-ui/core';
import OccupationAutocomplete from './OccupationAutocomplete'
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import OnboardingStepper from "./OnboardingStepper";
import {makeStyles} from "@material-ui/styles";
import Logo from "./Logo";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
    },
    subtitle: {
        paddingTop: "10px",
    }
}));

function OccupationStep({occupation, setOccupation, saveOccupationBackend}) {

    const classes = useStyles();
    const {t} = useTranslation()

    const [isLoading, setIsLoading] = useState(false);

    const saveOccupation = async () => {
        setIsLoading(true)
        await saveOccupationBackend()
    }

    return (
        <React.Fragment>
            <Container>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Logo/>
                </Grid>
                <OnboardingStepper activeStep={0}/>

                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item lg={6}>
                        <Typography align="center" variant="h5">{t('occupationStep.title')}</Typography>
                        <Typography align="center" className={classes.subtitle} variant="subtitle1">{t('occupationStep.instructions')}</Typography>
                    </Grid>
                </Grid>

                <Grid container direction="row" justify="center" alignItems="center" style={{paddingTop: "35px"}}>
                    <Grid item lg={10}>
                        <form noValidate autoComplete="off">
                            <div style={{textAlign: "center"}}>
                                <Typography component="span">{t('occupationStep.prompt')} </Typography>
                                <OccupationAutocomplete onChange={setOccupation}/>
                            </div>
                        </form>
                    </Grid>
                </Grid>

                <Grid container direction="row" justify="center" alignItems="center" style={{paddingTop: "50px"}}>
                    {
                        isLoading ? <CircularProgress /> : (
                        occupation ?
                            <Button size="large" onClick={saveOccupation} variant="contained" color="primary" >{t('next')} →</Button> :
                            <Button size="large" variant="contained" color="primary" disabled={true} >{t('next')} →</Button>
                        )
                    }
                </Grid>
            </Container>
        </React.Fragment>

    );
}

export default OccupationStep;
