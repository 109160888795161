import React from 'react';
import {Button, Container} from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import bookStack from "../../static/bookstack.svg";
import {makeStyles} from "@material-ui/core/styles";
import Logo from "./Logo";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    logo: {
        textAlign: "center",
        paddingTop: "25px"
    },
    paperContainer: {
        padding: "40px",
        marginTop: "25px"
    },
    subHeadingContainer: {
        textAlign:"center",
        padding: "25px"
    },
    saveButtonContainer: {
        padding: "50px"
    },
    callout: {
        color: theme.palette.primary.main
    },
    startButton: {
        marginTop: '40px',
        marginBottom: '40px'
    }
}))


function Welcome() {

    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <React.Fragment>
            <Container>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Logo/>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item lg={6}>
                        <Typography variant="h4">{t("welcome.title")} <span className={classes.callout}>21skills</span>!</Typography>
                        <Typography variant="subtitle1">{t("welcome.subtitle")}</Typography>
                        <Button component={Link} className={classes.startButton} to={"/occupation/start"} size="large" variant="contained" color="primary">{t("welcome.getStarted")} →</Button>
                    </Grid>
                    <Grid item lg={2}>
                    </Grid>
                    <Grid item lg={4}>
                        <img src={bookStack} height={"250px"} alt={"Book Stack Woman"} />
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>

    );
}

export default Welcome;
