import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useParams} from "react-router-dom";
import Overview from "./Overview";
import {fetchUser} from "../../util/backendApi";
import {CircularProgress} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        minHeight: '100vh'
    },
    loadingContainer: {
        display: 'flex',
        height: '100%'
    },
    loadingDivStyle: {
        margin: 'auto'
    }
}));

export default function DashboardContainer({fetchProps, essentialSkills, isFetchingSkills}) {
    const classes = useStyles();
    const {id} = useParams()

    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        fetchUser(id).then(fetchedUser => {
            setUser(fetchedUser);
        }).catch(error => {
            console.error(error)
        }).finally(() => {
            setIsLoading(false);
        })
    }, [id])

    useEffect(() => {
        if (!isFetchingSkills && !Object.keys(essentialSkills).length) {
            fetchProps(id)
        }
    }, [essentialSkills, fetchProps, id, isFetchingSkills])

    return (
        <div className={classes.root}>
            <main className={classes.content}>
                {isLoading ?
                    <div className={classes.loadingContainer}>
                        <div className={classes.loadingDivStyle}>
                            <CircularProgress/>
                        </div>
                    </div> :
                    <Overview user={user} essentialSkills={essentialSkills} />
                }
            </main>
        </div>
    );
}
