import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import {useParams} from "react-router-dom";
import {withStyles} from "@material-ui/core";
import StepConnector from "@material-ui/core/StepConnector";
import {useTranslation} from "react-i18next";

const Connector = withStyles((theme) => ({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: theme.palette.primary.main,
        },
    },
    completed: {
        '& $line': {
            borderColor: theme.palette.primary.main,
        },
    },
    line: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}))(StepConnector);

const useStyles = makeStyles((theme) => ({
    step: {
        cursor: "pointer",
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },

}));

export default function HorizontalLabelPositionBelowStepper(props) {
    const classes = useStyles();
    const {id} = useParams()

    const {t} = useTranslation()

    const steps = [{
        label: t("onboardingStepper.chooseAJob"),
        path: "/" + id
    }, {
        label: t("onboardingStepper.selectYourSkills"),
        path: "/essential-skills/" + id
    }, {
        label: t("onboardingStepper.rateYourSkills"),
        path: "/enjoy-skills/" + id
    }]

    return (
        <div className={classes.root}>
            <Stepper activeStep={props.activeStep} alternativeLabel connector={<Connector/>}>
                {steps.map((step) => (
                        <Step key={step.label}>
                            <StepLabel className={classes.step}>{step.label}</StepLabel>
                        </Step>
                ))}
            </Stepper>
        </div>
    );
}
