import React, {useEffect, useState} from 'react';
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import Reward from 'react-rewards';
import {Hidden} from "@material-ui/core";
import MobileOverview from "./MobileOverview";
import Emoji, {JoyEnum} from "../onboarding/Emoji";
import {Skeleton} from "@material-ui/lab";
import bulb from '../../static/lightbulb.svg';
import thumbsup from '../../static/thumbsup.svg';
import bookStack from '../../static/bookstack.svg';
import computerGuy from "../../static/computerGuy.svg";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    capitalize: {
        textTransform: 'capitalize',
    },
    title: {
        textTransform: 'capitalize',
        textAlign: 'center'
    },
    subtitle: {
        textAlign: 'center'
    },
    container: {
        margin: 30
    },
    lovedSkillContainer: {
        border: 'solid',
        borderRadius: 30,
        padding: 20,
        borderColor: '#DA0063',
        margin: 20,
        position: 'relative'
    },
    normalSkillContainer: {
        border: 'solid',
        borderRadius: 30,
        padding: 20,
        borderColor: '#0016da',
        margin: 20,
        position: 'relative'
    },
    missingEssentialSkillContainer: {
        border: 'solid',
        borderRadius: 30,
        padding: 20,
        borderColor: '#00c1da',
        margin: 20,
        position: 'relative'
    },
    missingOptionalSkillContainer: {
        border: 'solid',
        borderRadius: 30,
        padding: 20,
        borderColor: '#00c1da',
        margin: 20,
        position: 'relative'
    },
    skillTitle: {
        textTransform: 'capitalize',
        textAlign: 'center'
    },
    skillRatingAvatar: {
        margin: "auto",
        fontSize: "40px",
        position: 'absolute',
        right: 40,
        top: -27
    },
    emptyList: {
        textAlign: 'center',
        border: 'solid',
        borderRadius: 30,
        padding: 20,
        borderColor: '#909a9b',
        margin: 20,
        position: 'relative'
    },
    emptyListIcon: {
        fontSize: '3em',
    },
    missingSkillHeading: {
        textTransform: 'capitalize',
        marginLeft: '10px'
    },
    missingSkillSubHeading: {
        marginLeft: '10px',
        color: "rgba(0, 0, 0, 0.54)"
    },
    missingSkillPercentageJobAd: {
        fontWeight: "bold",
        color: "#dd8407"
    },
    missingSkillPercentageJobCv: {
        fontWeight: "bold",
        color: "#076edd"
    },
    paperContainer: {
        padding: '40px',
        textAlign: 'center'
    }
}));

let reward;

export default function Overview({user, essentialSkills}) {

    const classes = useStyles();
    const {t, i18n} = useTranslation()

    const [lovedSkills, setLovedSkills] = useState([])
    const [notLovedSkills, setNotLovedSkills] = useState([])
    const [missingEssentialSkills, setMissingEssentialSkills] = useState([])
    const [missingOptionalSkills, setMissingOptionalSkills] = useState([])

    useEffect(() => {
        if (reward && reward.rewardMe) {
            setTimeout(() => {
                reward.rewardMe()
            }, 500)
        }
    }, [])

    useEffect(() => {
        if (user) {
            setLovedSkills(getLovedSkills(user))
            setNotLovedSkills(getNotLovedSkills(user))
            setMissingEssentialSkills(getMissingEssentialSkills(user))
            setMissingOptionalSkills(getMissingOptionalSkills(user))
        }
    }, [user])

    const getLovedSkills = (user) => {
        if (user.skills) {
            return user.skills.filter(skill => {
                return skill.joyRating === JoyEnum.LOVE
            })
        }
        return []
    }

    const getNotLovedSkills = (user) => {
        if (user.skills) {
            return user.skills.filter(skill => {
                return skill.joyRating !== JoyEnum.LOVE
            })
        }
        return []
    }

    const getMissingEssentialSkills = (user) => {
        const essentialSkills = user.occupation._links.hasEssentialSkill
        if (essentialSkills) {
            return essentialSkills.filter(essentialSkill => {
                for (let i = 0; i < user.skills.length; i++) {
                    if (user.skills[i].uri === essentialSkill.uri) {
                        return false
                    }
                }
                return true
            })
        }
        return [];
    }

    const getMissingOptionalSkills = (user) => {
        const optionalSkills = user.occupation._links.hasOptionalSkill
        if (optionalSkills) {
            return optionalSkills.filter(optionalSkills => {
                for (let i = 0; i < user.skills.length; i++) {
                    if (user.skills[i].uri === optionalSkills.uri) {
                        return false
                    }
                }
                return true
            })
        }
        return []
    }

    const renderLovedSkills = (lovedSkills) => {
        if (lovedSkills.length > 0) {
            return lovedSkills.map(skill => {
                return (
                    <div key={skill.uri} className={classes.lovedSkillContainer}>
                        <Typography className={classes.title} variant="h6">{skill.preferredLabel[i18n.language]}</Typography>
                        <Typography>{skill.description[i18n.language]}</Typography>
                        <div className={classes.skillRatingAvatar}>
                            <Emoji joy={skill.joyRating}/>
                        </div>
                    </div>
                )
            })
        }
        return (
            <div className={classes.emptyList}>
                <NewReleasesIcon className={classes.emptyListIcon}/>
                <Typography variant="subtitle1">{t("overview.lovedSkillsEmpty")}</Typography>
            </div>
        )
    }

    const renderNormalSkills = (skills) => {
        if (skills.length > 0) {
            return skills.map(skill => {
                return (
                    <div key={skill.uri} className={classes.normalSkillContainer}>
                        <Typography className={classes.title} variant="h6">{skill.preferredLabel[i18n.language]}</Typography>
                        <Typography>{skill.description[i18n.language]}</Typography>
                        <div className={classes.skillRatingAvatar}>
                            <Emoji joy={skill.joyRating}/>
                        </div>
                    </div>
                )
            })
        } else {
            return (
                <div className={classes.emptyList}>
                    <NewReleasesIcon className={classes.emptyListIcon}/>
                    <Typography variant="subtitle1">{t("overview.normalSkillsEmpty")}</Typography>
                </div>
            )
        }
    }

    const renderMissingEssentialSkills = (missingEssentialSkills) => {
        if (missingEssentialSkills.length > 0) {
            return missingEssentialSkills.map(skill => {
                return (
                    <div key={skill.uri} className={classes.missingEssentialSkillContainer}>
                        <Typography className={classes.title} variant="h6">{skill.title}</Typography>
                        { essentialSkills && essentialSkills[skill.uri] ? <Typography>{essentialSkills[skill.uri].description[i18n.language].literal}</Typography> : renderLoadingDescription()}
                    </div>
                )
            })
        } else {
            return (
                <div className={classes.emptyList}>
                    <NewReleasesIcon className={classes.emptyListIcon}/>
                    <Typography variant="subtitle1">{t("overview.essentialSkillsEmpty")}</Typography>
                </div>
            )
        }
    }

    const renderLoadingDescription = () => {
        return (
            <React.Fragment>
                <Skeleton animation="wave" height={20} width="100%" style={{ marginBottom: 6 }} />
                <Skeleton animation="wave" height={20} width="100%" style={{ marginBottom: 6 }} />
                <Skeleton animation="wave" height={20} width="100%" style={{ marginBottom: 6 }} />
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <Hidden mdUp>
                <MobileOverview occupation={user.occupation} essentialSkills={essentialSkills} lovedSkills={lovedSkills} notLovedSkills={notLovedSkills} missingEssentialSkills={missingEssentialSkills} missingOptionalSkills={missingOptionalSkills}/>
            </Hidden>
            <Hidden smDown>
                <div className={classes.container}>
                    <Grid container justify="center" alignItems="center" spacing={3}>
                        <Grid item lg={12}>
                            <Grid container justify="center" alignItems="center" spacing={3}>
                                    <Grid item lg={10}>
                                        <Paper elevation={3}>
                                            <Grid container spacing={3}>
                                                <Grid item lg={8}>
                                                    <div  className={classes.paperContainer}>
                                                        <Reward
                                                            ref={(ref) => { reward = ref }}
                                                            type='confetti'
                                                            config={{
                                                                lifetime: 400,
                                                                spread: 180,
                                                                startVelocity: 35,
                                                                elementCount: 150,
                                                                springAnimation: false
                                                            }}
                                                        >
                                                            <Typography className={classes.title} variant="h5">{user.occupation.preferredLabel[i18n.language]}</Typography>
                                                            <Typography variant="subtitle1">{user.occupation.description[i18n.language].literal}</Typography>
                                                        </Reward>
                                                    </div>
                                                </Grid>
                                                <Grid item lg={4} style={{margin: 'auto'}}>
                                                    <img src={computerGuy} height={"200px"} alt={"Computer man"} />
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={12} style={{marginTop: 20}}>
                            <Grid container justify="center" alignItems="flex-start" spacing={3}>
                                <Grid item lg={6}>
                                    <Grid container justify="center" alignItems="flex-start" spacing={3}>
                                        <Grid item lg={12}>
                                            <div style={{position: 'relative', textAlign: 'center'}}>
                                                <img src={thumbsup} style={{height: 100}} alt="Thumbs up" />
                                            </div>
                                            <Typography className={classes.title} variant="h5">{t("overview.lovedSkillsTitle")}</Typography>
                                            <Typography className={classes.subtitle} variant="subtitle1">{t("overview.lovedSkillsSubtitle")}</Typography>
                                            {renderLovedSkills(lovedSkills)}

                                        </Grid>
                                        <Grid item lg={12}>
                                            <div style={{position: 'relative', textAlign: 'center'}}>
                                                <img src={bulb} style={{height: 100}} alt="Light Bulb"/>
                                            </div>
                                            <Typography className={classes.title} variant="h5">{t("overview.normalSkillsTitle")}</Typography>
                                            <Typography className={classes.subtitle} variant="subtitle1">{t("overview.normalSkillsSubtitle")}</Typography>
                                            {renderNormalSkills(notLovedSkills)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item lg={6}>
                                    <Grid container justify="center" alignItems="center" spacing={3}>
                                        <Grid item lg={12}>
                                            <div style={{position: 'relative', textAlign: 'center'}}>
                                                <img src={bookStack} style={{height: 100}} alt="Book stack"/>
                                            </div>
                                            <Typography className={classes.title} variant="h5">{t("overview.essentialSkillsTitle")}</Typography>
                                            <Typography className={classes.subtitle} variant="subtitle1">{t("overview.essentialSkillsSubtitle")}</Typography>
                                            {renderMissingEssentialSkills(missingEssentialSkills)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Hidden>
        </React.Fragment>
    )
}
