import React from 'react';
import {ThemeProvider} from '@material-ui/core/styles';
import {theme} from "./theme";
import {Route, Routes} from 'react-router-dom';
import OnboardingFlow from "./components/onboarding/OnboardingFlow";

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Routes>
                <Route path="/*" element={<OnboardingFlow />} />
            </Routes>
        </ThemeProvider>
    );
}

export default App;
